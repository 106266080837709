.topicHeader {
   display: flex;
   background-color: white;
   text-align: left;
   justify-content: center;
   height: 30px;
}

.TopicTitle {
   text-align: left;
}

.TopicTitle:hover {
   transition: all 0.3s ease-in;
   color: blue;
}