
.textDecoration
{
  a:link {
    text-decoration: none;
  }
  
  a:visited {
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  a:active {
    text-decoration: underline;
  }
}

.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font{
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="Audiowide"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Audiowide"]::before
{
    font-family: "Audiowide", sans-serif;
    font-weight: 400;
    font-style: normal;  
    content: "Audiowide" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Freeman"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Freeman"]::before
{
    font-family: "Freeman", sans-serif;
    font-weight: 400;
    font-style: normal;
    content: "Freeman" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Honk"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Honk"]::before
{
    font-family: "Honk", sans-serif;
    font-weight: 400;
    font-style: normal;
    content: "Honk" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Inter"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Inter"]::before
{
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-style: normal;
    content: "Inter" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Pacifico"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Pacifico"]::before
{
    font-family: "Pacifico", sans-serif;
    font-weight: 400;
    font-style: normal;
    content: "Pacifico" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Monoton"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Monoton"]::before
{
    font-family: "Monoton", sans-serif;
    font-weight: 400;
    font-style: normal;
    content: "Monoton" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Righteous"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Righteous"]::before
{
    font-family: "Righteous", sans-serif;
    font-weight: 400;
    font-style: normal;
    content: "Righteous" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Sevillana"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Sevillana"]::before
{
    font-family: "Sevillana", sans-serif;
    font-weight: 400;
    font-style: normal;
    content: "Sevillana" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Tangerine"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Tangerine"]::before
{
    font-family: "Tangerine", sans-serif;
    font-weight: 400;
    font-style: normal;
    content: "Tangerine" !important;
}


.ql-picker.ql-font .ql-picker-label[data-value="Ultra"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Ultra"]::before
{
    font-family: "Ultra", sans-serif;
    font-weight: 400;
    font-style: normal;
    content: "Ultra" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Michroma"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Michroma"]::before
{
    font-family: "Michroma", sans-serif;
    font-weight: 400;
    font-style: normal;
    content: "Michroma" !important;
}



/* Set content font-families */
.ql-font-Roboto {
  font-family: "Roboto";
}
.ql-font-Raleway {
  font-family: "Raleway";
}
.ql-font-Honk {
  font-family: "Honk";
}
.ql-font-Inter {
  font-family: "Inter";
}
.ql-font-Monoton {
  font-family: "Monoton";
}

.ql-font-Pacifico {
  font-family: "Pacifico";
}

.ql-font-Righteous {
  font-family: "Righteous";
}

.ql-font-Sevillana {
  font-family: "Sevillana";
}

.ql-font-Tangerine {
  font-family: "Tangerine";
}

.ql-font-Ultra {
  font-family: "Ultra";
}

.ql-font-Michroma {
  font-family: "Michroma";
}