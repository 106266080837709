.dropdownTerm {
    border: 1px solid #e2e1e6;
    border-radius: 10px;
    opacity: 1;
    top: 119px;
    left: 899px;
    width: 100%;
    height: 54px;
    margin-top: 45px;
    padding-left: 24px;
    padding-right: 20px;
    display: flex;
    align-items: center;
  }
  
  .buttonCreate {
    background: #2ab3bd 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    border: 1px solid #e2e1e6;
    border-radius: 10px;
    top: 119px;
    left: 899px;
    width: 205px;
    height: 54px;
    margin-top: 45px;
    padding-left: 24px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    text-align: left;
    font: normal normal normal 16px/20px Inter;
    letter-spacing: 0.01px;
    color: #ffffff;
    opacity: 1;
  }
  
  .fa {
    margin-left: 1em;
  }

  @media only screen and (max-width: 1200px) {
    .hello {
      width: 100% !important;
    }
  }
  