.custom-card {
   position: relative;
    width: 100%;
 }

 .draganddrop {
    font-family: sans-serif;
    text-align: center;
    //   border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200px;
    width: 46%;
    margin: 10px;
 }


 .result-container-myfiles
 {
   position: relative;
   flex-direction: column;
   margin-top:120px;
   justify-content: center;
   align-items: center;
   text-align: left;
   overflow-y:auto;
   // background-color: #0a8ba1;
   height: calc(100vh - 150px);
 }

 .image-container
 {
   // position: relative;
   display: block;
   // max-height: 100%;
 }