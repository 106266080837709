.CommonForm {
   /* background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box; */
   border: 1px solid var(--unnamed-color-e2e1e6);
   background: #F4FBFC 0% 0% no-repeat padding-box;
   border: 1px solid #E2E1E6;
   border-radius: 7px;
   opacity: 1;
   padding-left: 15px;
   padding-right: 15px;
}


.ImageDiv {
   background: var(--unnamed-color-f9f9f9) 0% 0% no-repeat padding-box;
   border: 2px solid var(--unnamed-color-2ab3bd);
   background: #F9F9F9 0% 0% no-repeat padding-box;
   border: 2px solid #2AB3BD;
   border-radius: 10px;
   opacity: 1;
   padding-left: 10%;
   padding-right: 10%;
   padding-top: 10%;
   padding-bottom: 10%;
   text-align: center;
   /* To correctly align image, regardless of content height: */
   vertical-align: top;
   display: inline-block;
   /* To horizontally center images and caption */
   text-align: center;
   /* The width of the container also implies margin around the images. */
   width: 50%;
}

.ImageDiv img {
   /* margin: 0 auto */
   width: 100px;
   height: 100px;
}

.ImageDiv img:hover {
   background-color: yellow;
}

.ImageDiv label:hover {
   background-color: yellow;
}


.student-div {
   border: 1px solid #ccc;
   overflow-y: auto;
   height: 36vh;
   background: #FFFFFF;
}

.fa {
   margin-left: 1em
}