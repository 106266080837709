body,
html {
   height: 100%;
}

.login-header {
   margin-bottom: 20px;
}

.login-header p {
   margin-bottom: 0;
}

.login-header h3 {
   font-size: 35px;
   margin-bottom: 0;
   text-transform: uppercase;
}

.login-header h3 span {
   //color: #70D0D4;
   color: green;
}

.login-header h3 a {
   //color: #70D0D4;
   color: green;
   float: right;
   font-size: 15px;
   margin-top: 2px;
}

.block_container {
   display: flex;
}

.align-left {
   float: left;
}

.align-right {
   float: right;
}