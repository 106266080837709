.fc-h-event {
  border-color: #2ab3bd !important;
  background-color: #2ab3bd !important;
}

.fc-event {
  /* force events to be one-line tall */
  white-space: nowrap !important;
  overflow: hidden;
}

/* .fc-h-event {
  display: block;
  border: 1px solid #2ab3bd !important;
  border: 1px solid var(--fc-event-border-color, #2ab3bd) !important;
  background-color: #2ab3bd !important;
  background-color: var(--fc-event-bg-color, #2ab3bd) !important;
} */
