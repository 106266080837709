.preview {
   margin-left: 5px;
   margin-right: 5px;
   margin-top: 10px;
   height: calc(100vh - 210px);
   justify-content: center;
   align-items: center;
   display: flex;
   background-color: #FFFFFF;
   text-align: center;
 }