.DetailsDiv {
    /* background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box; */
    border: 1px solid var(--unnamed-color-e2e1e6);
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    background: #F4FBFC;
    border: 1px solid #E2E1E6;
    border-radius: 7px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    padding-left: 15px;
    padding-right: 15px;

}

.dashboard-item-inner form input {
    height: 30px;
    border-radius: 7px;
}