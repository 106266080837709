.header-profile {
   padding-top: 10px;
   padding-left: 20px;
   width: 88%;
   height: 44px;
   opacity: 1;

   .name {
      text-align: left;
      font: normal normal normal 33px/44px Inter;
      letter-spacing: 0.01px;
      color: rgb(17, 17, 17);
   }

   .grade-level {
      text-align: left;
      font: normal normal normal 16px/26px Inter;
      letter-spacing: 0.01px;
      color: #AAE2E6;
      opacity: 1;
   }

   .number-box {
      background: #AAE2E6 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
      width: 109px;
      height: 32px;
   }

   .number-info {
      text-align: center;
      font: normal normal normal 16px/26px Inter;
      letter-spacing: 0.01px;
      color: #FFFFFF;
   }

   .profile_picture {
      height: 237px;
      background-color: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
   }
}