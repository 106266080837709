.LessonTitle {
   position: relative;
   width: 100%;
   height: 40px;
   text-align: left;
   font: normal normal normal 18px/17px Inter;
   letter-spacing: 0.01px;
   color: #000000;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   //background-color:blueviolet;
   opacity: 1;
}

.LessonButtons {
   display: flex;
   top: 80px;
   height: 40px;
   justify-content: flex-end;

   //background-color: aqua;
   .ButtonMenu {
      width: 64px;
      height: 38px;
      background: 0% 0% no-repeat padding-box;
      border: 1px solid #e2e1e6;
      //margin-right: 5px;
      //border-radius: 0px 10px 10px 0px;
      opacity: 1;
   }

   .ButtonLeft {
      width: 46px;
      height: 32px;
      min-width: 46px;
      background: 0% 0% no-repeat padding-box;
      border: 1px solid #e2e1e6;
      border-radius: 0px 7px 7px 0px;
      opacity: 1;

      &:hover {
         background-color: #7fc9ce;
      }
   }

   .ButtonRight {
      width: 46px;
      height: 32px;
      min-width: 46px;
      /* UI Properties */
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e2e1e6;
      border-radius: 7px 0px 0px 7px;
      opacity: 1;

      &:hover {
         background-color: #7fc9ce;
      }
   }

   .ButtonMiddle {
      cursor: pointer;
      width: 90px;
      height: 32px;
      /* UI Properties */
      background: #ffffff 0% 0% no-repeat;
      border: 1px solid #e2e1e6;
      opacity: 1;

      &:hover {
         background-color: #7fc9ce;
      }
   }


}


.SubLessonTitle {
   position: relative;
   width: 312px;
   height: 23px;
   top: -10px;
   text-align: left;
   font: normal normal medium 15px/19px Inter;
   letter-spacing: 0px;
   color: #a3a3ac;
   opacity: 1;
}


.sectionPanel {
   position: relative;
   width: 100%;
   height: 23px;
   text-align: left;
   margin-top: 20px;
   padding-top: 35px;
   font: normal normal medium 19px/23px Inter;
   letter-spacing: 0px;
   color: #000000;
   opacity: 1;
}

.subsectionType {
   position: relative;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 20px;
   text-align: left;
   font: normal normal bold 16px/20px Inter;
   padding: 10px;
   letter-spacing: 0px;
   color: #000000;
}

.active.ant-collapse>.ant-collapse-item>.ant-collapse-header {
   border-top: 3px solid black;
}

.lessonDescription {
   font: normal normal 13px/10px Inter;
   font-weight: bold;
   margin-bottom: 5px;
}

.truncateString {
   width: 10px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.individualLesson {
   display: flex;
   position: relative;
   margin-bottom: 10px;
   padding-left: 1px;
   // background-color: "red";
}

.individualLesson-dragging {
   font-weight: bold;
}

.individualLesson:hover #title {
   font-weight: bold;
}

.menu-lesson {
   display: block;
   position: absolute;
   top: 0;
   left: calc(100% - 70px);
   //background-color: red;
   z-index: 9;
   padding-left: 5px;
}

.menu-lesson-hide {
   display: none;
}

.lesson-icon-drag-show {
   display: block;
}

.lesson-icon-drag {
   display: block;
   position: absolute;
   top: 0;
   left: 0;
   //background-color: red;
   z-index: 19;
}

/* 
 .ItemLesson:hover {
  //background-color: gray;
  border: #000000;
  border-style: dotted;
}  */

.individualTopic {
   //display: flex;
   //position: relative;
   margin-top: 3px;
   //padding-left: 15px;
   border-bottom: 1px solid black;
   cursor: pointer;
   //background-color: "red";
}

.topic-icon-drag {
   display: none;
   position: absolute;
   top: 0;
   left: 0;
   //background-color: red;
   padding-right: 10px;
   padding-top: 2px;
   z-index: 22;
}

.individualTopic:hover .topic-icon-drag,
.individualTopic:hover,
.individualTopic:hover .menu-topic {
   display: block;
   //background-color: red;
   //font-weight: bold;
}


.menu-topic {
   display: none;
   position: absolute;
   top: 0;
   left: calc(100% - 70px);
   //background-color: red;
   z-index: 9;
   padding-left: 5px;
}

.subjectTitle {
   position: relative;
   width: 100%;
   height: 28px;
   text-align: left;
   //padding-top: 5px;
   //padding-bottom: 5px;
   font: normal normal normal 24px/23px Inter;
   letter-spacing: 0.01px;
   color: #000000;
   /*     white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;  */
   //background-color:red;
   opacity: 1;
}

#card-stack .card:hover {
   box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.50);
   transition: all 0.3s ease-in;
   transform: scale(1.05);
}

.button-side-menu {
   display: flex;
   position: fixed;
   right: -135px;
   top: calc(50% - 120px);
   z-index: 99;
   font-size: 20px;
   border: none;
   outline: none;
   border-color: white;
   background-color: #2ab3bd;
   color: white;
   cursor: pointer;
   padding: 10px;
   border-radius: 8px;
   text-align: center;
}

.button-side-menu:hover {
   right: -5px;
   font-weight: bold;
   transition: all 0.3s ease-in;
}


.ant-modal-header {
   //   height: 45px;
   position: relative;
}

.parent {
   position: relative;
   width: 100%;
   height: 100%;
   margin: 10px;
   color: #e2e1e6;
}

.child1 {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 15%;
   opacity: 0.9;
}

.child2 {
   z-index: 1;
   color: #e2e1e6;
   position: absolute;
   width: 50%;
   height: 95%;
   opacity: 0.7;
   padding: 10px;
   margin: 10px 10px;
   border-radius: 10px 10px 10px 10px;
   text-align: center;
   box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
   background: black;
}



.btn {
   background-color: white;
   border: 1px solid gray;
   //border-radius: 1rem;
   color: gray;
   padding: 0.5rem;
   text-transform: lowercase;
}

.btn--block {
   display: block;
   width: 100%;
}

.cards {
   display: flex;
   flex-wrap: wrap;
   list-style: none;
   margin: 0;
   padding: 0;
}

.card_autoplay {
   display: flex;
   padding: 2rem;

   .card__image {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 200px;
   }

   .card {
      background-color: white;
      border-radius: 0.25rem;
      box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 1.55);
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &:hover {
         .card__image {
            filter: contrast(100%);
         }
      }

      min-width: 300px;
      max-width: 300px;
   }

   .card__title {
      color: black;
      font-size: 1.85rem;

      font-weight: 300;
      letter-spacing: 1px;
      text-transform: uppercase;
   }

   .card__text {
      flex: 1 1 auto;
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 1rem;
      text-align: center;
      text-justify: inter-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
   }

   .card__image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
      height: 290px;
   }
}

.cards__item {
   display: flex;
   padding: 1rem;

   @media(min-width: 30rem) {
      width: 270px;
   }

   @media(min-width: 40rem) {
      width: 200px;
   }

   @media(min-width: 56rem) {
      width: 270px;
   }
}

.card {
   background-color: white;
   border-radius: 0.25rem;
   box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
   display: flex;
   flex-direction: column;
   overflow: hidden;

   &:hover {
      .card__image {
         filter: contrast(100%);
      }
   }

   min-width: 235px;
}

.card__content {
   display: flex;
   flex: 1 1 auto;
   flex-direction: column;
   padding: 1rem;
}

.card__image {
   // background-size: 150px;
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   /* Center the image */
   // width: 235px;
   // max-width: 235px;
   height: 200px;
}

.card__title {
   color: black;
   font: normal normal bold 16px/20px Inter;
   font-size: .85rem;
   font-weight: 500;
   letter-spacing: 1px;
   text-transform: uppercase;
}

.card__text {
   color: gray;
   flex: 1 1 auto;
   font-size: 0.82rem;
   line-height: 1.5;
   margin-bottom: 1rem;
   text-align: center;
   text-justify: inter-word;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 4;
   -webkit-box-orient: vertical;
}

.zoom_picker_container {
   height: calc(100vh - 200px);
   /* width: 30%; */
   padding: '0 16px';
   border: '1px solid rgba(140, 140, 140, 0.35)';
   overflow: auto;
}

.downloadLink {
   background-color: rgb(72, 72, 240);
   color: white;
   padding: 0.6em 1.5em;
   border-radius: 15px;

}

.ant-btn>span {
   display: inline-flex;
}

// .anticon {
//    vertical-align: 0.2em !important;
// }

/* @media screen and (min-width: 1601px) and (max-width: 1860px) {  
    .subjectTitle
    {
        position: relative;
        width: 420px;
        height: 28px;
        text-align: left;
        //padding-top: 5px;  
        //padding-bottom: 5px;    
        font: normal normal normal 24px/23px Inter;
        letter-spacing: 0.01px;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;    
        background-color:red;
        opacity: 1;   
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .subjectTitle
    {
        position: relative;
        width: 280px;
        height: 28px;
        text-align: left;
        //padding-top: 5px;  
        //padding-bottom: 5px;    
        font: normal normal normal 20px/19px Inter;
        letter-spacing: 0.01px;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;    
        background-color:green;
        opacity: 1;   
    }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
    .subjectTitle
    {
        position: relative;
        width: 420px;
        height: 28px;
        text-align: left;
        //padding-top: 5px;  
        //padding-bottom: 5px;    
        font: normal normal normal 14px/13px Inter;
        letter-spacing: 0.01px;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;    
        background-color:yellow;
        opacity: 1;   
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .subjectTitle
    {
        position: relative;
        width: 420px;
        height: 28px;
        text-align: left;
        //padding-top: 5px;  
        //padding-bottom: 5px;    
        font: normal normal normal 24px/23px Inter;
        letter-spacing: 0.01px;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;    
        background-color:pink;
        opacity: 1;   
    }
}
 */