.report-card-container_empty {
   margin-left: 5px;
   margin-right: 5px;
   margin-top: 10px;
   height: calc(100vh - 155px);
   justify-content: center;
   align-items: center;
   display: flex;
   /* background-color: #F4FBFC; */
   opacity: 50%;
 }

 .report-card-container {
   max-height: calc(100vh - 145px) !important;
 }