.panel-item-close {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    z-index: 2;
}
.c2 {
    margin-left: -6px;
    border: none;
    right: 8px;
    position: relative;
    justify-content: center;
    position: absolute;
    color: #fff;
    top: 50%;
    transform: translate(100%, -50%);
}

.nextArrowBtn{
    position: absolute;
    cursor: pointer;
    z-index: 1000;
    top: 50%;
    right: 0;
    color: #141414;
    margin-right: 10px;
}
.prevArrowBtn{
    position: absolute;
    cursor: pointer;
    z-index: 1000;
    top: 50%;
    left: 0;
    color: #141414;
    margin-left: 10px;
}


.canvasViewer
{
    display : flex;
    top : 1px;
    position :absolute;
    background-size : 100% 100%;
    border : 1px solid black;
    width : 900px; 
    height  : 620px;
    justify-content : center;
    align-items : center;
    transform-origin: 2px 2px;
    margin : auto;
    overflow : hidden;
}

.canvasViewerEditor
{
  display : flex;
  position :absolute;
  left: 10%; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;  
  background-size : 100% 100%;
  border : 1px solid #CFCFCF;
  border-radius: 7px;
  width : 900px; 
  height  : 620px;
  justify-content : center;
  align-items : center;
  transform-origin: 2px 2px;
//   margin : auto;
  overflow : hidden;
}

/*
====================================
large Screen - Others
====================================
*/

@media screen and (min-width: 1921px) and (max-width: 3840px) { 
     .canvasViewer
{
        transform : scale(1);
        transform-origin: center center;
        // left : 10%;
        border : 5px solid green;
    }  

    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }

    .canvasViewerEditor_1
    {
        left: 1px; 
        transform : translate(15px, 15px) scale(1,1);
        // border : 5px solid green;
    } 
}

// ---------------------------------------------------------------

@media screen and (min-width: 1601px) and (max-width: 1920px) and (max-height: 360px){
     .canvasViewer
{
        transform : translate(15px, 15px) scale(0.9,0.8);
        border : 5px solid blue;
    }   
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }         
    .canvasViewerEditor_1
    {
        // left: 1px; 
        transform : translate(15px, 15px) scale(0.9,0.8);
        // border : 5px solid blue;
    }      
}
@media screen and (min-width: 1601px) and (max-width: 1920px) and (min-height: 361px) and (max-height: 720px){
     .canvasViewer
{
        transform : translate(center, center) scale(0.9,0.8);
        border : 5px solid blue;
    }   
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }            
    .canvasViewerEditor_1
    {
        // left: 1px; 
        transform : translate(15px, 15px) scale(0.9,0.8);
        // border : 5px solid blue;
    }     
}
@media screen and (min-width: 1601px) and (max-width: 1920px) and (min-height: 721px)  and (max-height: 1080px){
     .canvasViewer
{
        left : 10%;
        transform : translate(15px, 15px) scale(1,1);
        border : 5px solid blue;
    }   
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }        
    .canvasViewerEditor_1
    {
        left: 1px; 
        transform : translate(15px, 15px) scale(1,1);
        // border : 5px solid blue;
    }       
}


// @media screen and (min-width: 1601px) and (max-width: 1920px) {
//     .canvasViewer
//     {
//         // transform : translate(15px, 15px) scale(1,1);
//         left : 10%;
//         // border : 5px solid red;
//     }  
//     .canvasViewerContainer
//     {
//         height : calc(100vh - 288px);
//     }    
//     .canvasViewerEditor_1
//     {
//         left: 1px; 
//         // transform : translate(15px, 15px) scale(1,1);
//         // border : 5px solid burlywood;
//     } 
// }

// ---------------------------------------------------------------
@media (min-width: 1441px) and (max-width: 1600px) and (max-height: 360px){
     .canvasViewer
{
        transform : translate(5px, 5px) scale(0.88,0.72);
        left : 10%;
        // border : 5px solid green;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }          
    .canvasViewerEditor_1
    {
        transform : translate(5px, 5px) scale(0.88,0.72);
        // border : 5px solid green;
    } 
}

@media (min-width: 1441px) and (max-width: 1600px) and (min-height: 361px) and (max-height: 740px){
     .canvasViewer
{
        // transform : translate(5px, 5px) scale(0.88,0.65);
        left : 15%;
        // border : 5px solid green;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }       
    .canvasViewerEditor_1
    {
        transform : translate(5px, 5px) scale(0.88,0.68);
        // border : 5px solid green;
    } 
}
@media (min-width: 1441px) and (max-width: 1600px) and (min-height: 741px)  and (max-height: 1080px){
     .canvasViewer
{
        // transform : translate(5px, 5px) scale(0.9,0.9);
        left : 2%;
        // border : 5px solid green;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }          
    .canvasViewerEditor_1
    {
        transform : translate(5px, 5px) scale(0.9,0.9);
        // border : 5px solid green;
    } 
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .canvasViewer
    {
        // transform : translate(5px, 25px) scale(0.8,0.65);
        left : 1%;
        // border : 5px solid green;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }      
    .canvasViewerEditor_1
    {
        left: 15%; 
        // transform : translate(5px, 5px) scale(0.9,0.9);
        // border : 5px solid yellow;
    }      
}

// ---------------------------------------------------------------

@media screen and (min-width: 1351px) and (max-width: 1400px) and (max-height: 360px){
     .canvasViewer
{
    left : 15%;
        // transform : translate(25px, 25px) scale(0.9,0.8);
        // border : 5px solid violet;
    } 
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }          
    .canvasViewerEditor_1
    {
        left : 10%;
        transform : translate(15px, 15px) scale(0.9,0.72);
        // border : 5px solid violet;
    }   
}
@media screen and (min-width: 1351px) and (max-width: 1400px) and (min-height: 361px) and (max-height: 740px){
     .canvasViewer
{
        // transform : translate(25px, 25px) scale(0.9,0.62);
        left : 15%;
        // border : 5px solid violet;
    } 
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }          
    .canvasViewerEditor_1
    {
        left : 10%;
        transform : translate(15px, 15px) scale(0.9,0.66);
        // border : 5px solid violet;
    }   
}
@media screen and (min-width: 1351px) and (max-width: 1400px) and (min-height: 741px)  and (max-height: 1080px){

     .canvasViewer
{
        // left : 1%;
        // transform : translate(25px, 25px) scale(0.9,0.9);
        // border : 5px solid violet;
    } 
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }          
    .canvasViewerEditor_1
    {
        left : 10%;
        transform : translate(15px, 15px) scale(0.9,0.9);
        // border : 5px solid violet;
    }         
}

// @media screen and (min-width: 1351px) and (max-width: 1400px) {
//     .canvasViewer
//     {
//         transform : translate(25px, 25px) scale(0.90,0.9);
//         border : 5px solid violet;
//     } 
//     .canvasViewerContainer
//     {
//         height : calc(100vh - 288px);
//     }          
//     .canvasViewerEditor_1
//     {
//         left : 10%;
//         // transform : translate(15px, 15px) scale(0.9,0.9);
//         border : 5px solid violet;
//     }          
// }

// ---------------------------------------------------------------


@media screen and (min-width: 992px) and (max-width: 1350px) and (max-height: 360px){
     .canvasViewer
    {
       left : 7%;
        // transform: translate(5px, 5px) scale(0.88, 0.7);
        // border: 5px solid beige;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }           
    .canvasViewerEditor_1
    {
        transform : translate(15px, 15px) scale(0.88,0.7);
        // border: 5px solid beige;
    }      
}
@media screen and (min-width: 992px) and (max-width: 1350px) and (min-height: 361px) and (max-height: 740px){
     .canvasViewer
{
        left : 7%;
        // transform: translate(5px, 5px) scale(0.88, 0.65);
        // border: 5px solid beige;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }           
    .canvasViewerEditor_1
    {
        transform : translate(15px, 15px) scale(0.88,0.65);
        // border: 5px solid beige;
    }    
}
@media screen and (min-width: 992px) and (max-width: 1350px) and (min-height: 741px)  and (max-height: 1080px){
     .canvasViewer
{
        // left : 7%;
        // transform: translate(5px, 5px) scale(0.88, 0.9);
        // border: 5px solid beige;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }           
    .canvasViewerEditor_1
    {
        transform : translate(15px, 15px) scale(0.88,0.9);
        // border: 5px solid beige;
    }       
}

// @media screen and (min-width: 992px) and (max-width: 1350px) {
//     .canvasViewer
//     {
//         transform: translate(5px, 5px) scale(0.88, 0.9);
//         border: 5px solid yellow;
//     }  
//     .canvasViewerContainer
//     {
//         height : calc(100vh - 288px);
//     }           
//     .canvasViewerEditor_1
//     {
//         // transform : translate(15px, 15px) scale(0.88,0.9);
//         // border: 5px solid green;
//     }      
// }

// ---------------------------------------------------------------

@media screen and (min-width: 768px) and (max-width: 991px) and (max-height: 360px){
     .canvasViewer
{
        // transform : translate(-20px, 5px) scale(0.82,0.68);
        left : 4%;
        // border : 5px solid blueviolet;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }       
    .canvasViewerEditor_1
    {
        left : 10%;
        transform : translate(5px, 5px) scale(0.72,0.72);
        // border : 5px solid blueviolet;
    }       
}
@media screen and (min-width: 768px) and (max-width: 991px) and (min-height: 361px) and (max-height: 740px){
     .canvasViewer
{
        // transform : translate(-20px, 5px) scale(0.82,0.65);
        left : 4%;
        // border : 5px solid blueviolet;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }       
    .canvasViewerEditor_1
    {
        left : 10%;
        transform : translate(5px, 5px) scale(0.72,0.69);
        // border : 5px solid blueviolet;
    }    
}
@media screen and (min-width: 768px) and (max-width: 991px) and (min-height: 741px)  and (max-height: 1080px){
     .canvasViewer
{
        // transform : translate(-20px, 5px) scale(0.82,0.9);
        // left : 4%;
        // border : 5px solid blueviolet;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 288px);
    }       
    .canvasViewerEditor_1
    {
        left : 10%;
        transform : translate(5px, 5px) scale(0.72,0.9);
        // border : 5px solid blueviolet;
    }    
}

// @media screen and (min-width: 768px) and (max-width: 991px) {
//     .canvasViewer
//     {
//         transform : translate(-20px, 5px) scale(0.82,0.9);
//         left : 1px !important;
//         // border : 5px solid rgb(19, 157, 12);
//     }  
//     .canvasViewerContainer
//     {
//         height : calc(100vh - 288px);
//     }       
//     .canvasViewerEditor_1
//     {
//         left : 10%;
//         transform : translate(5px, 5px) scale(0.72,0.9);
//         border : 5px solid red;
//     }          
// }


// ---------------------------------------------------------------

@media  screen and (min-width: 521px)  and (max-width: 767px) and (max-height: 360px){
     .canvasViewer
{
    left : 6%;
        // transform :  translate(5px, 5px)  scale(0.67,0.45);
        // border : 5px solid yellowgreen;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 440px);
    }           
    .canvasViewerEditor_1
    {
        left : 0px;
        transform : translate(5px, 5px) scale(0.65,0.73);
        // border : 5px solid yellowgreen;
    }      
}
@media  screen and (min-width: 521px)  and (max-width: 767px)and (min-height: 361px) and (max-height: 740px){
     .canvasViewer
{
    left : 6%;
        // transform :  translate(5px, 5px)  scale(0.67,0.42);
        // border : 5px solid yellowgreen;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 440px);
    }           
    .canvasViewerEditor_1
    {
        left : 0px;
        transform : translate(5px, 5px) scale(0.65,0.70);
        // border : 5px solid yellowgreen;
    }      
}
@media  screen and (min-width: 521px)  and (max-width: 767px) and (min-height: 741px)  and (max-height: 1080px){
     .canvasViewer
{
    // left : 6%;
        // transform :  translate(5px, 5px)  scale(0.67,0.78);
        // border : 5px solid yellowgreen;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 440px);
    }           
    .canvasViewerEditor_1
    {
        left : 0px;
        transform : translate(5px, 5px) scale(0.65,0.83);
        // border : 5px solid yellowgreen;
    }   
}

// @media screen and (min-width: 521px)  and (max-width: 767px) {
//     .canvasViewer
//     {
//         transform :  translate(5px, 5px)  scale(0.67,0.78);
//         // border : 5px solid rgb(12, 150, 157);
//     }  
//     .canvasViewerContainer
//     {
//         height : calc(100vh - 440px);
//     }           
//     .canvasViewerEditor_1
//     {
//         left : 0px;
//         transform : translate(5px, 5px) scale(0.65,0.83);
//         border : 5px solid rgb(12, 150, 157);
//     }          
// }


// ---------------------------------------------------------------

@media screen and (max-width: 520px) {
     .canvasViewer
{
        // transform :  translate(5px, 5px)  scale(0.48,0.42);
        left : 10px;
        // border : 5px solid palevioletred;
    }  
    .canvasViewerContainer
    {
        height : calc(100vh - 440px);
    }           
    .canvasViewerEditor_1
    {
        left : 0px;
        transform : translate(5px, 5px) scale(0.42,0.63);
        // border : 5px solid palevioletred;
    }     
}
// @media screen and (max-width: 520px) and (min-height: 741px) and  (min-height: 361px) and (max-height: 740px){
//     .canvasViewer
//     {
//         left : 10px;
//         transform :  translate(5px, 5px)  scale(0.48,0.45);
//         border : 5px solid rgb(157, 31, 12);
//     }  
//     .canvasViewerContainer
//     {
//         height : calc(100vh - 440px);
//     }           
//     .canvasViewerEditor_1
//     {
//         left : 0px;
//         transform : translate(5px, 5px) scale(0.42,0.66);
//         // border : 5px solid rgb(157, 31, 12);
//     }  
// }
// @media screen and (max-width: 520px) and (min-height: 741px)  and (max-height: 1080px){
//     .canvasViewer
//     {
//         transform :  translate(5px, 5px)  scale(0.50,0.45);
//         border : 5px solid rgb(157, 31, 12);
//     }  
//     .canvasViewerContainer
//     {
//         height : calc(100vh - 440px);
//     }           
//     .canvasViewerEditor_1
//     {
//         left : 0px;
//         transform : translate(5px, 5px) scale(0.43,0.66);
//         // border : 5px solid rgb(157, 31, 12);
//     }  
// }

// @media screen and (max-width: 520px) {
//     .canvasViewer
//     {
//         transform :  translate(5px, 5px)  scale(0.50,0.70);
//         // border : 5px solid rgb(157, 31, 12);
//     }  
//     .canvasViewerContainer
//     {
//         height : calc(100vh - 440px);
//     }           
//     .canvasViewerEditor_1
//     {
//         left : 0px;
//         transform : translate(5px, 5px) scale(0.43,0.66);
//         // border : 5px solid rgb(157, 31, 12);
//     }          
// }


.very-specific-design {
    width : 900px; 
    height  : 620px;
    // border : 1px solid black;
    border : 1px solid #CFCFCF;
    border-radius: 5px;
    text-align: center;
    position: relative;
    left: 50%;
    top: 50%;
    -moz-background-size:100% 100%;
    -webkit-background-size:100% 100%;
    background-size:100% 100%;    
    // transform: translate(-50%, -50%) scale(1);
    transform-origin: center center;
    overflow: hidden;
  }
  
  .scaleable-wrapper {
    // display: flex;
    height : calc(100vh - 288px); 
    width : 100%;
    position : relative;
    align-content : center;
    justify-content : center;
    padding: 20px;
    // background-color: white;
    margin-bottom: 5px;
    // border: 1px solid black;
  }
  
  .slide-in {
    animation: slideIn 0.5s ease-in-out;
    border-top : 1px solid #629296;
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

