.videoBox {
   top: 249px;
   left: 209px;
   width: 100%;
   height: 520px;
   background-color: black;
   margin-right: 20px;
   opacity: 1;
}

.searchSide {
   margin-left: 12px;
   border: rgb(155, 149, 149);
   //  border-style: solid;
   //  border-width: 0.5px;
   width: 100%;
   height: 230px;
   //  overflow-y: scroll;
   border-radius: 7px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   padding: 5px;
}

.searchSideEmpty {
   margin-left: 12px;
   border: rgb(155, 149, 149);
   //  border-style: solid;
   //  border-width: 0.5px;
   width: 100%;
   height: 230px;
   justify-content: center;
   align-items: center;
   display: flex;
   //  border-radius: "50px";
   border-radius: 7px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.custom-card-selected {
   width: 150px;
   height: 150px;
}

.custom-card {
   margin: "5px";
   position: relative;
   width: 100%;
}

.custom-card>.ant-card-body {
   width: 100%;
   height: 100%;
   padding: 5px;
}

.custom-card>.ant-card-body>p {
   text-align: center;
   vertical-align: middle;
   display: table-cell;
}

.draganddrop {
   font-family: sans-serif;
   text-align: center;
   //   border-style: solid;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   height: 200px;
   width: 46%;
   margin: 10px;
}

.overlay {
   position: fixed;
   width: 100%;
   height: 100%;
   background: black center center no-repeat;
   opacity: .5;
}

#mce_fullscreen_container {
   background: none;
   background: rgba(0, 0, 0, 0.6);
   -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
   zoom: 1;
}

table#mce_fullscreen_tbl.mceLayout {
   margin: 20px auto 0;
}