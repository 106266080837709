
  .logo{
    width: 100%;
    height: 50px;
    margin: auto;
    justify-items: center;
    align-items: center;
    text-align: center;
    //margin-bottom:20px;
    margin-top: 10px;
  }

  .container-search
  {
    width: 90%;
    margin: auto;
    justify-content: center;
    align-items: center;  
  }
  .search-group
  {
    display: flex;
    margin: auto;
    width: 100%;
    justify-content: center;
    align-items: center;

  }

  .bar{
    margin:0 auto;
    height: 30px;
    width:215px;
    border-radius:30px;
    border:1px solid #dcdcdc;
    // background-color: #4885ed;
    left: 50px;
  }
  .bar:hover{
    box-shadow: 1px 1px 8px 1px #dcdcdc;
  }
  .bar:focus-within{
    box-shadow: 1px 1px 8px 1px #dcdcdc;
    outline:none;
  }
  
  .voice{
    height:20px;
    position:relative;
    top:1px;
    left: -15px;
  }

  .buttons-group{
    display: flex;
    margin:0 auto;
    margin-top:10px;
    justify-content: center;
    align-items: center;
    // background-color: aqua;
  }
  .button-search{
    background-color: #d4cdcd;
    border:none;
    color: black;
    height: 30px;
    margin: 0px;
    border-radius:4px;
    outline:none;
  }
  .button-search:hover{
    border: 1px solid #060606;
    color:black;
  }
  .button-search:focus{
    border:1px solid #4885ed;
  }

  .result-container
  {
    position: relative;
    flex-direction: column;
    margin-top:40px;
    justify-content: center;
    align-items: center;
    text-align: left;
    overflow-y:auto;
    // background-color: #0a8ba1;
    height: calc(100vh - 18em);
  }

  .result-container-myfiles
  {
    position: relative;
    flex-direction: column;
    margin-top:40px;
    justify-content: center;
    align-items: center;
    text-align: left;
    overflow-y:auto;
    // background-color: #0a8ba1;
    height: calc(100vh - 150px);
  }

  .image-container
  {
    // position: relative;
    display: block;
    // max-height: 100%;
  }

  .image-result:hover{
    box-shadow: 4px 4px 8px 4px #292525;
    width: 255px;
    height: 185px;
  }

  
  .image-result
  {
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    position: relative;
    width: 250px;
    height: 180px;
    border-radius: 5px;
    border:1px solid black;
  }

  .info-style
  {
    width: 100%;
    // padding-left: 25px;
    padding-top: 8px;   
    margin-left: 30px;
    margin-right: 30px;
    text-align: left;
  }
  .author-style
  {
    font-weight: bold;
    padding-bottom: 5px;
    color : white;
  }

  .description-style
  {
    color : white;
  }

  .date-style
  {
    color:black;
  }

  .buttonSlideLeft
  {
    position: absolute;
    width: 15px;
    height: 70px;
    right: -5px;
    top: 40%;
    cursor: pointer;
    transform: translateY(-50%);
    z-index: 10000000;
    border: none;
    background-color: #333;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

// .buttonSlideLeft::before
// {
//     content: '';
//     position: absolute;
//     width: 15px;
//     height:20px;
//     background-color: transparent;
//     top: -19px;
//     left: 0px;
//     border-bottom-left-radius: 17px;
//     box-shadow: -7px 2px #333;
// }

// .buttonSlideLeft::after
// {
//     content: '';
//     position: absolute;
//     width: 15px;
//     height:20px;
//     background-color: transparent;
//     top: 69px;
//     left: -1px;
//     border-top-left-radius: 17px;
//     box-shadow: -7px -11px #333;
// }
  