@import "~antd/dist/antd.less";

// .ant-input,
// .ant-btn-round.ant-btn-sm,
// .ant-btn-round.ant-btn-lg,

.ant-btn-round,
.ant-btn-round.ant-btn-sm,
.ant-btn-round.ant-btn-lg {
   border-radius: 7px;
}

.ant-select {
   border-radius: 7px;
   /* min-height: 40px; */
}

/* .ant-select-single .ant-select-selector .ant-select-selection-item,
 .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
   line-height: 38px;
 } */

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
   border-radius: 7px;
   /* min-height: 40px; */
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
   border-radius: 7px !important;
   /* min-height: 40px; */
}

.ant-select-dropdown {
   border-radius: 7px !important;
}

.ant-select-multiple .ant-select-selection-item {
   border-radius: 7px !important;
}

.ant-table.ant-table-small,
.ant-table.ant-table-medium {
   font-size: 14px !important;

   // min-width: 500px !important;
}

.ant-picker,
.ant-picker:hover,
.ant-picker-focused {
   border-radius: 7px !important;
   min-height: 40px;
}

.ant-picker-panel-container {
   border-radius: 7px !important;
}

.ant-picker-cell .ant-picker-cell-inner {
   border-radius: 2px !important;
}

// .anticon {
//    vertical-align: 0.2em !important;
// }

.ant-message-notice-content {
   border-radius: 7px !important;
}

.ant-tooltip-inner {
   border-radius: 7px !important;
}

.ant-popover-inner {
   border-radius: 7px !important;
}

.ant-card {
   border-radius: 7px !important;
}

.ant-card-bordered {
   border: 1px solid #e6e4e4 !important;
   margin-bottom: 7px;
}

.ant-input-number-group>.ant-input-number:last-child,
.ant-input-number-group-addon:last-child,
.ant-input-group>.ant-input:last-child,
.ant-input-group-addon:last-child {
   border-top-right-radius: 7px !important;
   border-bottom-right-radius: 7px !important;
}

.ant-input-number-group:hover>.ant-input-number:last-child,
.ant-input-number-group-addon:last-child {
   border-top-right-radius: 0 !important;
   border-bottom-right-radius: 0 !important;
}

.ant-input-number-group:focus>.ant-input-number:last-child,
.ant-input-number-group-addon:last-child {
   border-top-right-radius: 0 !important;
   border-bottom-right-radius: 0 !important;
}

.ant-input-number-group>.ant-input-number:first-child,
.ant-input-number-group-addon:first-child,
.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
   border-top-left-radius: 7px !important;
   border-bottom-left-radius: 7px !important;
}

textarea.ant-input {
   border-radius: 7px !important;
}

.ant-notification-notice {
   border-radius: 7px !important;
}

.ant-radio-button-wrapper:first-child {
   border-radius: 7px 0 0 7px !important;
}

.ant-radio-button-wrapper:last-child {
   border-radius: 0 7px 7px 0 !important;
}

.ant-modal-content,
.ant-modal-header,
.ant-modal-footer {
   border-radius: 7px !important;
   //   background-color: #f4fbfc !important;
}

.ant-modal-confirm .ant-modal-body {
   border-radius: 7px !important;
}

.ant-modal-body {
   background-color: #f4fbfc !important;
}

// .ant-btn {
//   border-radius: 7px !important;
// }

.ant-descriptions-view {
   border-radius: 7px !important;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
   // background-color: #f4fbfc;
   background-color: #D5F4F9 !important;
}

.ant-tag {
   border-radius: 7px !important;
}

.tox-statusbar__branding {
   display: none !important;
}

.tox-button {
   background-color: #4abec7 !important;
   border-color: #4abec7 !important;
   border-radius: 7px !important;
}

.tox-tinymce {
   border-radius: 7px !important;
}

.ant-tabs-tab .anticon {
   margin-right: 0 !important;
}

.ant-table table {
   font-size: 13px;
}

.ant-dropdown-menu {
   border-radius: 7px;
}

// .ant-table-expanded-row .ant-table-expanded-row-level-1 .ant-table-cell {
//    background-color: #F4FBFC !important;
// }

.code-box-demo {
   overflow-x: auto;
}

.code-box-demo .ant-segmented {
   margin-bottom: 10px;
}

.ant-segmented-item {
   // color: white;
   border-radius: 7px;
   margin-bottom: 0px;
   padding-bottom: 1px;
   transition: #70D0D4 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-segmented-item-selected {
   background-color: #70D0D4;
   border-radius: 7px;
   color: white;
}

.ant-segmented-thumb {
   // color: white;
   background-color: #70D0D4;
   border-radius: 7px;
}

.ant-segmented-block {
   border-radius: 7px;
}

// .ant-segmented-group {
//    padding: 1px;
// }

.ant-table-thead>tr>th {
   // background: #f2fdff !important;
   background: #D5F4F9 !important;
   // color: #fff !important;
}

.ant-layout-sider-trigger {
   background: #D5F4F9 !important;
}

// .ant-upload.ant-upload-select {
//    display: block !important;
// }

// .ant-menu-sub.ant-menu-inline {
//    font-size: 13px;
// }

// .ant-layout-sider {
//    transition: none !important;
// }

// .ant-menu-item .ant-menu-item-icon+span,
// .ant-menu-submenu-title .ant-menu-item-icon+span,
// .ant-menu-item .anticon+span,
// .ant-menu-submenu-title .anticon+span {
//    transition: none !important;
// }

.ant-card-head-title {
   flex: none !important;
}

.ant-breadcrumb {
   padding-top: 5px;
   padding-bottom: 5px;
   // font-size: 14px;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
   color: #000 !important;
}

.ant-select-disabled .ant-select-arrow {
   display: none !important;
}

.ant-tree .ant-tree-node-content-wrapper {
   text-align: left !important;
}

.ant-btn-group>.ant-btn:first-child:not(:last-child),
.ant-btn-group>span:first-child:not(:last-child)>.ant-btn {
   border-top-left-radius: 7px !important;
   border-bottom-left-radius: 7px !important;
}

.ant-btn-group>.ant-btn:last-child:not(:first-child),
.ant-btn-group>span:last-child:not(:first-child)>.ant-btn {
   border-top-right-radius: 7px !important;
   border-bottom-right-radius: 7px !important;
}

// .ant-menu-item-active .ant-menu-title-content {
//    color: #6bfdea !important
// }

// .ant-menu-submenu-active .ant-menu-title-content {
//    color: #6bfdea !important
// }

.ant-input-group-addon {
   background-color: #D5F4F9 !important;
}

// .ant-space-item {
//    width: 100% !important;
// }

.ant-carousel .slick-dots li.slick-active button {
   background: #4ABEC7;
}

.ant-carousel .slick-dots li button {
   background: #4ABEC7
}

.ant-radio-disabled+span {
   color: rgba(0, 0, 0, 1);
}

.ql-editor {
   background: #FFF !important;
   // border-bottom: none !important;
   // border-top: none !important;
   // border-left: none !important;
   // border-radius: none !important;
}

.ql-container {
   border-bottom: none !important;
   border-top: none !important;
   border-left: none !important;
   border-right: none !important;
}

.ql-toolbar {
   // border-bottom: none !important;
   border-top: none !important;
   border-left: none !important;
   border-right: none !important;
}
@primary-color: #4abec7;