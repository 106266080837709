.form-section {
   // margin: 10px 0;
}

.sendbutton {
   width: 100%;
   border-radius: 7px;
   margin-top: 7px;
   border: none;
}

.form-control {
   margin-bottom: 20px;
   padding: 20px;
   font-size: 1rem;
   font-family: 'Poppins',
      sans-serif;
   outline: none;
   background-color: #bcd5ef;
   color: #050505;
   transition: all 0.5s ease-in-out;
}

.form-control:focus {
   border-top-left-radius: 0%;
   border-bottom-left-radius: 0%;
   border-top-right-radius: 0%;
}

.btn {
   background: #000000;
   color: #DADADB;
   padding: 10px 0;
   font-size: 1.2rem;
   font-weight: 500;
   cursor: pointer;
   transition: all 0.5s ease-in-out;
}

//   .btn:hover {
//     border-top-left-radius: 0%;
//     border-bottom-left-radius: 0%;
//     border-top-right-radius: 0%;
//   }

.answer-section {
   // margin: 10px 0;
   position: relative;
}

.answer-section .question {
   background-color: #5997D2;
   border-radius: 20px;
   padding: 5px 15px 5px 15px;
   margin-right: 10px;
   // font-weight: bold;
   color: white;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-size: 15px;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.answer-section .answer {
   //border: #000000;
   //border-style: solid;
   border-radius: 20px;
   margin-top: 8px;
   margin-left: 10px;
   background-color: #EAF5F7;
   padding: 10px 15px 10px 15px;
   white-space: pre-wrap;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-size: 15px;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   // box-shadow: -1px 4px 4px -1px rgba(0, 0, 0, 0.2);
   // -webkit-box-shadow: 0 4px 4px -1px #ccc;
   // -moz-box-shadow: 0 4px 4px -1px #ccc;
}

.answer-container {
   // overflow-y: scroll;
   background-color: white;
   max-height: calc(100vh - 350px);
   min-height: calc(100vh - 350px);
   border: solid;
   border-width: 1px;
   // min-height: 300px;
   border-color: #DADADB;
   margin-bottom: 10px;
   // margin-top: 20px;
   // padding: 5px 0px 5px 0px;
   border-radius: 7px;
}

.stream-answer-container {
   // position: relative;
   background-color: white;
   max-height: calc(100vh - 185px);
   min-height: calc(100vh - 185px);
   border: solid;
   border-width: 1px;
   border-color: #DADADB;
   // margin-bottom: 10px;
   border-radius: 7px;
   // padding: 10px;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-size: 16px;
}

.copy-icon {
   position: absolute;
   color: #70D0D4;
   width: 30px;
   height: 30px;
   border-radius: 50%;
   background-color: #FFF;
   display: flex;
   justify-content: center;
   align-items: center;
   top: -10px;
   left: 58px;
   font-size: 1.3rem;
   cursor: pointer;
   transition: all 0.5s ease-in-out;
   box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.stream-copy-icon {
   position: absolute;
   color: #fff;
   width: 40px;
   height: 40px;
   border-radius: 50%;
   background-color: #70D0D4;
   display: flex;
   justify-content: center;
   align-items: center;
   top: 0;
   //right: 0;
   font-size: 1.5rem;
   cursor: pointer;
   transition: all 0.2s ease-in-out;
   // box-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
   opacity: 50%;
   z-index: 1001;
   margin: 10px;
}

.stream-copy-icon:hover {
   opacity: 100%;
   box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7);
}

.hr-line {
   margin-bottom: 20px;
}

.typing {
   width: 4.1em;
   height: 2em;
   position: relative;
   padding: 14px 10px 10px 10px;
   margin-left: 5px;
   margin-top: 15px;
   margin-bottom: 5px;
   background: #e6e6e6;
   border-radius: 20px;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.typing__dot {
   float: left;
   width: 5px;
   height: 5px;
   margin: 0 5px;
   background: #8d8c91;
   border-radius: 50%;
   opacity: 0;
   animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
   animation-delay: 0s;
}

.typing__dot:nth-child(2) {
   animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
   animation-delay: 0.4s;
}

@keyframes loadingFade {
   0% {
      opacity: 0;
   }

   50% {
      opacity: 0.8;
   }

   100% {
      opacity: 0;
   }
}