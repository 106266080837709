/*
====================================
large Screen - Others
====================================
*/

@media screen and (min-width: 1601px) and (max-width: 1860px) {
   .lesson-description {
      cursor: pointer;
      margin-left: 25px;
      width: 100%;
      max-width: 85%;
      //background-color: aqua;
      //cursor: "pointer", marginLeft: "25px", width : '100%' , textOverflow : "clip" , maxWidth : "240px" 
   }

   .text-display {
      text-overflow: ellipsis;
      line-height: 1;
      max-width: 73%;
      overflow: hidden;
      padding-left: 10px;
      //  background-color: aqua;
      text-align: left;
   }

   .text-display-view {
      text-overflow: ellipsis;
      line-height: 1;
      max-width: 78%;
      overflow: hidden;
      padding-left: 10px;
   }


}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
   .lesson-description {
      cursor: pointer;
      margin-left: 25px;
      width: 100%;
      text-overflow: clip;
      max-width: 73%;
      //cursor: "pointer", marginLeft: "25px", width : '100%' , textOverflow : "clip" , maxWidth : "240px" 
   }

   .text-display {
      text-overflow: clip;
      text-overflow: ellipsis;
      line-height: 1;
      max-width: 70%;
      overflow: hidden;
      padding-left: 10px;
      text-align: left;
   }

   .text-display-view {
      text-overflow: ellipsis;
      line-height: 1;
      max-width: 74%;
      overflow: hidden;
      padding-left: 10px;
   }

}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
   .lesson-description {
      cursor: pointer;
      margin-left: 25px;
      width: 100%;
      text-overflow: clip;
      max-width: 70%;
      //background-color: green;
      //cursor: "pointer", marginLeft: "25px", width : '100%' , textOverflow : "clip" , maxWidth : "240px" 
   }

   .text-display {
      text-overflow: clip;
      text-overflow: ellipsis;
      line-height: 1;
      max-width: 64%;
      overflow: hidden;
      padding-left: 10px;
      text-align: left;
   }

   .text-display-view {
      text-overflow: ellipsis;
      line-height: 1;
      max-width: 72%;
      overflow: hidden;
      padding-left: 10px;
      //background-color: yellow;
   }

}


@media screen and (min-width: 992px) and (max-width: 1200px) {
   .lesson-description {
      cursor: pointer;
      margin-left: 25px;
      width: 100%;
      text-overflow: clip;
      max-width: 100%;
      //  background-color: cyan;
      //cursor: "pointer", marginLeft: "25px", width : '100%' , textOverflow : "clip" , maxWidth : "240px" 
   }

   .text-display {
      text-overflow: clip;
      text-overflow: ellipsis;
      line-height: 1;
      max-width: 80%;
      overflow: hidden;
      padding-left: 10px;
      text-align: left;
   }

   .text-display-view {
      text-overflow: ellipsis;
      line-height: 1;
      max-width: 80%;
      overflow: hidden;
      padding-left: 10px;
      // background-color: yellow;
   }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
   .lesson-description {
      cursor: pointer;
      margin-left: 25px;
      width: 100%;
      text-overflow: clip;
      max-width: 100%;
      //    background-color: gray;
      //cursor: "pointer", marginLeft: "25px", width : '100%' , textOverflow : "clip" , maxWidth : "240px" 
   }

   .text-display {
      text-overflow: clip;
      text-overflow: ellipsis;
      line-height: 1;
      max-width: 70%;
      overflow: hidden;
      padding-left: 10px;
      text-align: left;
   }

   .text-display-view {
      text-overflow: ellipsis;
      line-height: 1;
      max-width: 85%;
      overflow: hidden;
      padding-left: 10px;
   }
}

@media screen and (max-width: 767px) {
   .lesson-description {
      cursor: pointer;
      margin-left: 25px;
      width: 100%;
      text-overflow: clip;
      max-width: 100%;
      //    background-color: gray;
      //cursor: "pointer", marginLeft: "25px", width : '100%' , textOverflow : "clip" , maxWidth : "240px" 
   }

   .text-display {
      text-overflow: clip;
      text-overflow: ellipsis;
      line-height: 1;
      max-width: 60%;
      overflow: hidden;
      padding-left: 10px;
      text-align: left;
   }

   .text-display-view {
      text-overflow: ellipsis;
      line-height: 1;
      max-width: 77%;
      overflow: hidden;
      padding-left: 10px;
   }
}