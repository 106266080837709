/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:600|Work+Sans:400,500,600,700&display=swap"); */


#loginform {
   max-width: 400px;
   min-width: 300px;
   /* max-height: 700px; */
   width: 30%;
   height: inherit;
   margin: 25px auto;
   background-color: #F4FBFC;
   border-radius: 7px;
   /* border: 1px solid #ccc; */
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#headerTitle {
   text-align: center;
   font-family: "open sans", sans-serif;
   padding: 10px 0;
   margin: 0;
   font-size: 2rem;
}

.row-checkbox {
   display: flex;
   align-items: center;
   justify-content: center;
}

.row-login {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-top: 1rem;
   max-width: 100%;
}

.row-login input {
   width: 80%;
   box-sizing: border-box;
   border: none;
   font-size: 1.3rem;
   padding-left: 1.5rem;
   padding-bottom: 1rem;
   box-shadow: inset 0px -3px 0px 0px rgba(187, 187, 187, 0.2);
   transition: box-shadow 0.2s ease-in;
}

.row-login input:focus {
   box-shadow: inset 0px -3px 0px 0px rgba(34, 193, 195, 0.7);
   outline: none;
}

.row-login input::-webkit-input-placeholder {
   opacity: 1;
   transition: opacity 0.25s ease-out;
}


.row-login label {
   align-self: start;
   padding-left: 3.5rem;
   padding-bottom: 0.5rem;
   color: rgba(17, 17, 17, 0.9);
}

.row-login button {
   border-radius: 7px;
   width: 80%;
   height: 40px;
   font-size: 1.3rem;
   color: white;
   font-weight: 700;
   background: rgb(34, 193, 195);
   /* background: linear-gradient(
    90deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(253, 187, 45, 1) 100%
  ); */
   border: 0px;
   cursor: pointer;
   transition: opacity 0.25s ease-out;
}



.row-login button:hover {
   opacity: 0.8;
   background: rgb(10, 121, 123);
   color: white;
}

#button {
   padding-bottom: 1.5rem;
}


#iconGroup {
   display: flex;
   justify-content: center;
   padding: 2rem 0;
}

#iconGroup a {
   margin: 0 0.6rem;
}

#alternativeLogin {
   text-align: center;
   padding-top: 2rem;
   margin-top: 1.5rem;
}

.forgot-password,
.forgot-password a {
   text-align: right;
   font-size: 13px;
   padding-top: 10px;
   color: #7f7d7d;
   margin: 0;
}

.forgot-password a {
   color: #167bff;
}


.header-logo {
   display: flex;
   justify-content: center;
   padding-top: 5px;
   background-color: rgba(255, 255, 255, 0.3);
}