.container_ai
{
    z-index : 1 ;
    position: relative;
    width: 290px;
    height: 215px;
    border-radius : 5px;
    margin : auto;
    border : 1px solid gray;    
}

.thumname_ai
{
    z-index: 1;
    cursor : pointer;
    background-color : #bcecee;
    // background-image; :  "";
    background-size : 100% 100%;
    transform : scale(0.35);
    transform-origin : 1px 1px;
    // border : "1px solid black",
    position: relative;
    display : flex;
    width : 820px;
    justify-content : center;
    align-items : center;
    height  : 620px;
    margin : auto;
    overflow : hidden;
}

.image_container
{
    position : absolute;
    left: 50px;
    top: 50px;
    width: 500px; 
    height: 500px;     
    transform : translate(-39px, 5.5px) scale(0.938, 0.79);                 
    font-family: Arial;
    font-size: 13px;
    padding: 0;
    // float : left;    
}

.header_container
{
    position : absolute;
    left: 50px;
    top: 50px;
    width: 680px; 
    height: 65px;     
    transform :  translate(-17.883px, -18px) ;                 
    font-family: Arial;
    font-size: 13px;
    padding: 0;
    // float : left;     
}

.sub-topic_container
{
    position : absolute;
    left: 50px;
    top: 50px;
    width: 326px; 
    height: 376px;     
    transform :  translate(440px, 77px) ;                 
    font-family: Arial;
    font-size: 13px;
    padding: 0;
}