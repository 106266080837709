.item-container {
   width: 80px;
   height: 100px;
   border-radius: 7px;
   display: flex;
   justify-content: center;
   align-items: start;
   background-color: #ececec;
   box-shadow: 0px 0px 1px 1px #ccc;
   // padding: 1px;
   // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   // -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   // -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   position: relative;
}

.item-title {
   position: absolute;
   text-align: left;
   font-size: smaller;
   width: 60px;
   bottom: 0px;
   margin: 0 auto;
   padding: 3px;
}