

  .play-btn {
    background: red;
    display: inline-block;
    padding: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
  
  .play-btn:after {
    content: "";
    display: block;
    position: relative;
    left: 2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 20px;
    border-color: transparent transparent transparent white;
  }


  .web-icon {
    display: inline-block;
    background: white;
    // padding: 25px;    
    position: absolute;
    top: 50%;
    left: 50%;
    // height: 120px;
    width: 80px;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    z-index: 9999;
  }
  