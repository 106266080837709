/* .student_list_container {
   max-height: calc(100vh - 185px);
   padding: '0 16px';
   border: '1px solid rgba(140, 140, 140, 0.35)';
   overflow: auto;
} */

.essay_student:hover {
   background-color: #FFF;
}

.essay_student_selected {
   background-color: #ffffff;
   border-left: solid #70d0d4;
   /* border-right: solid #70d0d4; */
   border-width: 3px;
   /* border-top: outset #70d0d4;
   border-bottom: solid #70d0d4 !important; */
}

.essay-student-selected {
   background-color: #FFF;
}

.ant-list-split .ant-list-item {
   border-bottom: #70d0d4;
}