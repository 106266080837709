/* For pdf */
.react-pdf__Page {
   padding-top: 10px;
   /* border-radius: 15px !important; */
}

.react-pdf__Page__canvas {
   margin: 0 auto;
   width: 98% !important;
   height: 98% !important;
}

.react-pdf__Page__textContent {
   width: 98% !important;
   height: 98% !important;
   border: 1px solid rgb(0, 0, 0, 0.1);
   border-radius: 7px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.react-pdf__Page__annotations.annotationLayer {
   padding: 10px;
}

/* For all pages */
.pdf-all-page-container {
   width: 100%;
   /* height: calc(100vh - 65px); */
   /* margin-top: 10px; */
   /* overflow: auto; */
}

.pdf-all-page-container .simplebar-wrapper {
   max-height: calc(100vh - 65px) !important;
}

/* .pdf-all-page-container .simplebar-wrapper {
height: 100% !important;
} */

.button-selection-toggle-container {
   /* padding: 5px; */
   /* background: rgb(255, 255, 255); */
   margin-bottom: 5px;
   /* opacity: 90%; */
}

.answer-sheet-selection-show {
   padding: 5px;
   background: rgb(255, 255, 255);
   opacity: 90%;
   border-radius: 7px;
   margin-bottom: 5px;
   border: 1px solid rgb(223, 223, 223);
   box-shadow: 0 4px 4px -2px #ccc;
   -webkit-box-shadow: 0 4px 4px -2px #ccc;
   -moz-box-shadow: 0 4px 4px -2px #ccc;
   transform: translateY(0%);
   transition: 0.5s all ease;
}

.answer-sheet-selection-hide {
   transform: translateY(-130%);
   transition: 0.5s all ease;
}

/* .answer-sheet-selection .ant-btn-round {
font-size: 12px !important;
} */

.answer-sheet-content {
   /* padding-left: 25px;
   padding-right: 25px; */
   margin-left: 5px;
   margin-right: 5px;
   margin-top: 10px;
   height: calc(100vh -15px);
   /* background: #f4fbfc !important; */
}

.answer-sheet-content2 {
   /* padding-left: 25px;
    padding-right: 25px; */
   margin-left: 5px;
   margin-right: 5px;
   margin-top: 10px;
   height: calc(100vh - 65px);
   /* background: #f4fbfc !important; */
}

.answer-sheet-content .simplebar-wrapper {
   /* padding-left: 15px;
   padding-right: 15px; */
   height: calc(100vh - 30px) !important;
}

.answer-sheet-content2 .simplebar-wrapper {
   height: calc(100vh - 65px) !important;
}

.site-button-ghost-wrapper {
   padding: 26px 16px 16px;
   background: rgb(190, 200, 200);
}

.simplebar-content ul {
   padding-left: 0em !important;
}

.assign-assessment-container {
   margin-top: 5px;
   height: calc(100vh - 20px);
   padding: 5px;
}

.assign-assessment-container-drawer {
   /* margin-top: 5px; */
   height: calc(100vh - 115px);
   padding: 5px;
   overflow: hidden;
}

.react-pdf__message--no-data,
.react-pdf__message--loading {
   display: none !important;
}

.button-shadow {
   box-shadow: 0 4px 4px -1px #ccc;
   -webkit-box-shadow: 0 4px 4px -1px #ccc;
   -moz-box-shadow: 0 4px 4px -1px #ccc;
}

.assessment-keys-selection-area {
   position: fixed;
   top: 0;
   left: 0;
   background: #f9fbfc;
   z-index: 9999999999;
}

.collapse-css-transition {
   position: relative;
   transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.collapse-css-transition:after {
   transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
   pointer-events: none;
   opacity: 0;
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: block;
   background: rgba(255, 255, 255, 0);
   /* background: linear-gradient(
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.8)
  ); */
}

.collapse-css-transition.--c-collapsed:after {
   opacity: 1;
}

.notice-area {
   padding-top: 0 !important;
}

.ant-radio-button-wrapper-disabled:first-child {
   color: rgba(0, 0, 0, 1) !important;
}

.ant-picker,
.ant-picker:hover,
.ant-picker-focused {
   border-top-left-radius: 7px !important;
   border-bottom-left-radius: 7px !important;
   /* border-left: 0 !important; */
   min-height: 0 !important;
}

.DraftEditor-root {
   overflow: auto !important;
   position: relative;
   background-color: #ffff;
   /* border: 1px solid #ccc; */
   border-radius: 7px;
   padding-left: 10px;
   padding-right: 10px;
   max-height: 150px;
   min-height: 150px;
}

/* .ant-descriptions-item-content {
  text-align: center !important;
} */

.ant-table.ant-table-small,
.ant-table.ant-table-medium {
   font-size: 14px !important;
   /* min-width: 500px !important; */
}

.ant-descriptions-item-label {
   padding: 4px 8px !important;
}

/* .ant-drawer-body {
   overflow: hidden !important;
} */

/* .ant-drawer-content-wrapper {
   width: 500px !important;
} */

.ant-typography,
.ant-typography p {
   margin-bottom: 0 !important;
}

/* @media screen and (max-width: 600px) {
   .ant-drawer-content-wrapper {
      width: 400px !important;
   }

   .ant-table {
      min-width: 600px;
   }
} */

.mastery_details_div {
   border: 1px solid var(--unnamed-color-e2e1e6);
   background: #F4FBFC;
   border: 1px solid #E2E1E6;
   border-radius: 7px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   opacity: 1;
   padding-left: 15px;
   padding-right: 15px;
}