.container {
	margin: 0 auto;
}

.icon-container {
	// margin: 20px auto;
	// width: 90px;
	// height: 90px;
	cursor: pointer;
    border: 1px solid black;
	border-radius: 10%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
    text-align: center;
	background-color: #ececec;
	position: relative;
}
