/* .student_list_container {
   max-height: calc(100vh - 185px);
   padding: '0 16px';
   border: '1px solid rgba(140, 140, 140, 0.35)';
   overflow: auto;
} */

.ant-list-split .ant-list-item {
   border-bottom: #70d0d4;
}

.essay_image_container {
   height: 190px;
   overflow: auto;
   background-color: #FFF;
   display: flex;
   justify-content: center;
   align-items: center;
}

.document-file:hover {
   border-radius: 7px;
   background-color: #70d0d4;
   color: #fff;
   cursor: pointer;
   text-overflow: ellipsis;
   white-space: nowrap;
   margin: 4px;
   /* text-transform: uppercase; */
}

.document-file-selected {
   border-radius: 7px;
   background-color: #70d0d4;
   color: #fff;
   cursor: pointer;
   text-overflow: ellipsis;
   white-space: nowrap;
   margin: 4px;
   /* text-transform: uppercase; */
}