/* .student_list_container {
   max-height: calc(100vh - 185px);
   padding: '0 16px';
   border: '1px solid rgba(140, 140, 140, 0.35)';
   overflow: auto;
} */

.essay_student:hover {
   background-color: #FFF;
   /* border-left: outset #70d0d4; */
}

.essay_student_selected {
   background-color: #ffffff;
   border-left: solid #70d0d4;
   /* border-right: solid #70d0d4; */
   border-width: 3px;
   /* border-top: outset #70d0d4;
   border-bottom: solid #70d0d4 !important; */
}


.ant-list-split .ant-list-item {
   border-bottom: #70d0d4;
}

/* .ant-table-cell {
   background-color: #d7e5f5 !important;
} */

/* .ant-descriptions-bordered .ant-descriptions-item-label {
   background-color: #e5fafd;
} */

/* .ant-table-cell .ant-table-expanded-row .ant-table-expanded-row-level-1 {
   background-color: #b3e2ff !important;
} */

.ant-table-expanded-row>.ant-table-cell {
   background-color: #e5fafd !important;
}

.recommendation_view {
   height: calc(100vh - 55px);
   overflow: auto;
   background-color: #FFF;
   padding-right: 8px;
   /* display: flex;
   align-items: center;
   justify-content: center; */
}

.recommendation_view_modal {
   margin-left: 5px;
   margin-right: 5px;
   margin-top: 10px;
   height: calc(100vh - 230px);
   justify-content: center;
   align-items: center;
   display: flex;
   opacity: 100%;
}