


 /*  .buttonSlideLeft
  {
    position: absolute;
    width: 15px;
    height: 70px;
    right: -15px;
    top: 40%;
    cursor: pointer;
    transform: translateY(-50%);
    z-index: 10;
    border: none;
    background-color: #333;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

.buttonSlideLeft::before
{
    content: '';
    position: absolute;
    width: 15px;
    height:20px;
    background-color: transparent;
    top: -19px;
    left: 0px;
    border-bottom-left-radius: 17px;
    box-shadow: -7px 2px #333;
}

.buttonSlideLeft::after
{
    content: '';
    position: absolute;
    width: 15px;
    height:20px;
    background-color: transparent;
    top: 69px;
    left: -1px;
    border-top-left-radius: 17px;
    box-shadow: -7px -11px #333;
}

.buttonSlideBottom{
    position: relative;
    width: 80px;
    height: 17px;
    top: -40px;
    left: 50%;
    cursor: pointer;
    transform: translateX(-50%);
    z-index: 10;
    border: none;
    background-color: white;
    color: #111111;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  .buttonSlideBottom::before
  {
      content: '';
      position: absolute;
      width: 20px;
      height:15px;
      background-color: transparent;
      top: 2px;
      left: -20px;
      border-bottom-right-radius: 17px;
      box-shadow: 7px 5px white;
  }
  
  .buttonSlideBottom::after
  {
      content: '';
      position: absolute;
      width: 20px;
      height:15px;
      background-color: transparent;
      top: 2px;
      left: 80px;
      border-bottom-left-radius: 17px;
      box-shadow: -7px 5px white;
  }

 .canvasboard{
    width: 60%;
    height: 75%;
    background-color: white;
    border: #111111;
    border-style: solid;
    
    position: relative;
    top:10%;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;
  } */
 //Color Variables
 $sidebar-background-color: #b5e3eb;
 $active-sidebar-link-color: #0a8ba1;
 $hover-sidebar-link-color: $active-sidebar-link-color;
 $active-link-color: white;
 $tooltip-background-color: $sidebar-background-color;


  .page-content {
    display: flex;
    height: calc(100vh - 6.5em);
    flex-flow: column;
     background-color: white;
  }
  
  .container2 {
    position: relative;
    flex: 1;
    display: flex;
    min-width: 250px;
    flex-direction: column;
    border: 1px solid black;
    //background-color: blueviolet;
  }

  .container-canvas
  {
    position: relative;
    display:flex ;
    height: 100%;
  }

  .toolbar-canvas
  {
    // width: 80px;
    // min-width: 70px;
    flex : 1 0 80px;
    // background-color: aqua;
  }

.sub-menu
{
  position: relative;
  overflow-y: scroll;
  // flex : 1 1 380px;
  // box-shadow: 2px 0 5px -2px #888;
  background-color: $active-sidebar-link-color;
}

.main .canvas{
  position: relative;
  display: flex;
  width: 100%;
  min-width: 50%;
  // height: 100vh;
  flex-direction: column;
  background-color: wheat;
}


  .canvas-container
  {
    //background-color: aquamarine;
    // flex : 1 1 auto;
    display: flex;
    width: 100%;
  }
  .main-canvas
  {
    display : flex;
    flex-direction: column;
    flex : 1;
  }
  .main-canvas-board
  {
    display: flex;
    flex : 1 1 auto;
    background-color: white;
    justify-content: center;
    align-items: center;
  }


  .header-canvas
  {
    display: flex;
    background-color: white;
    flex : 0 1 50px;
    box-shadow: 0 5px 5px -5px #333;
     border-left: 5px solid rgb(238, 238, 238);
    //  margin: auto;
     justify-content: left;
     align-items: center;
    // min-height: 180px;
  }
  
  .footer-canvas
  {
    background-color: white;
    flex : 0 1 120px;
    box-shadow: 0 -5px 5px -5px #333;
    border-left: 5px solid rgb(238, 238, 238);
    // border-top: 1px solid black;
    // min-height: 180px;
  }

.sidebar-navigation {
	min-height: 100%;
	background-color: $sidebar-background-color;
  // box-shadow: 0 15px 0 rgba(0,0,0,0.75);
  left: 0;
	
	ul {
		text-align: center;
		//color: black;
    background-color: $sidebar-background-color;
    margin: 0;
    padding: 0;
    padding-top: 20px;
		li {
      margin: 0;
			padding: 20px 0;
			cursor: pointer;
			transition: all ease-out 120ms;
			
			i {
				display: block;
				font-size: 17px;
        margin: 0;
				transition: all ease 450ms;
			}
			
			.tooltip {
				display: inline-block;
				position: absolute;
				background-color: black;
        color: white;
				padding: 8px 15px;
				border-radius: 3px;
				margin-top: -26px;
				left: 185px;
				opacity: 0;
				visibility: hidden;
				font-size: 13px;
				letter-spacing: .5px;
				
				&:before {
					content: '';
					display: block;
					position: absolute;
					left: -4px;
					top: 10px;
					transform: rotate(45deg);
					width: 10px;
					height: 10px;
					background-color: inherit;
				}
			}
			
			&:hover {
				background-color: $hover-sidebar-link-color;
				
				.tooltip {
					visibility: visible;
					opacity: 1;
				}
			}
			
			&.active {
				background-color: $active-sidebar-link-color;
				
				i {
					color: $active-link-color;
				}
			}			
		}
	}
}
  
.main {
  display: flex;
  height: calc(100vh - 6.5em);
  flex-flow: row;
  border: 1px solid #CFCFCF;
  // background-color: aqua;
}

.main .leftsidemenu{
  position: relative;
  display: flex;
  transition: all ease-out 120ms;
  width: 340px;
  left: 0px;
  min-width: 340px;
  height: 100%;
  flex-direction: column;
  background-color: $active-sidebar-link-color;
}

.main .leftsidemenu button{
  position: absolute;
  width: 15px;
  height: 70px;
  right: -15px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 10;
  border: none;
  background-color: $active-sidebar-link-color;
  color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 0%;
  font-weight: bold;
}

.main .leftsidemenu button::before
{
  content: '';
  position: absolute;
  width: 15px;
  height:20px;
  background-color: transparent;
  top: -19px;
  left: 0px;
  border-bottom-left-radius: 17px;
  box-shadow: -7px 2px $active-sidebar-link-color;
}

.main .leftsidemenu button::after
{
  content: '';
  position: absolute;
  width: 15px;
  height:20px;
  background-color: transparent;
  top: 69px;
  left: -1px;
  border-top-left-radius: 17px;
  box-shadow: -7px -11px $active-sidebar-link-color;
}


.very-specific-design {
  width : 900px; 
  height  : 620px;
  // border : 1px solid black;
  border : 1px solid #CFCFCF;
  border-radius: 7px;
  text-align: center;
  position: relative;
  left: 50%;
  top: 50%;
  -moz-background-size:100% 100%;
  -webkit-background-size:100% 100%;
  background-size:100% 100%;    
  // transform: translate(-50%, -50%) scale(1);
  transform-origin: center center;
  overflow: hidden;
}

.scaleable-wrapper {
  // display: flex;
  height : calc(100vh - 288px); 
  width : 100%;
  position : relative;
  align-content : center;
  justify-content : center;
  padding: 20px;
  // border: 1px solid black;
}




