.panel-items-list {
    width: 72px;
    background: #0e1419;
    color: #fff;
  }
  
  .panel-items-list-item {
    font-family: 'Mukta';
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 72px;
    text-align: center;
    font-size: 0.8rem;
    position: relative;
    cursor: pointer;
    transition: color 0.2s;
    color: rgba($color: #ffffff, $alpha: 0.8);
    &:hover {
      color: rgba($color: #ffffff, $alpha: 1);
    }
    &.active {
      background: #29303a;
      color: rgba($color: #ffffff, $alpha: 1);
    }
    span {
      padding-top: 0.2rem;
    }
  }
  
  .panel-items-list-item.active::before {
    background: radial-gradient(circle closest-side, transparent 0, transparent 50%, #0e4f53 0) 200% 200%/400%
      400%;
    top: -8px;
    content: '';
    position: absolute;
    right: 0;
    height: 8px;
    width: 8px;
    overflow: hidden;
  }
  
  .panel-items-list-item.active::after {
    border-right: none !important;
    height: 100%;
    top: 8px;
    right: -1px;
    transform: scaleY(-1);
    background: radial-gradient(circle closest-side, transparent 0, transparent 50%, #29303a 0) 200% 200%/400%
      400%;
    width: 8px;
    overflow: hidden;
    content: '';
    position: absolute;
  }
  
  .panels {
    position: relative;
    background: #29303a;
    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
    display: flex;
    user-select: none;
    font-family: 'Lexend';
  }
  
  .panel-item-close {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    z-index: 2;
  }
  
  .c1 {
    margin-left: -6px;
    cursor: pointer;
    border: none;
    background: none;
    overflow: hidden;
    pointer-events: none;
    position: relative;
    color: #fff;
    right: -5px;
  }
  
  .c2 {
    margin-left: -6px;
    border: none;
    right: 8px;
    position: relative;
    justify-content: center;
    position: absolute;
    color: #fff;
    top: 50%;
    transform: translate(100%, -50%);
  }
  
  .panel-item-container {
    width: 0;
    transition: width 0.5s;
    position: relative;
    overflow: hidden;
    display: flex;
    &.open {
      width: 360px;
    }
  }

  .canvasBox {
    position : relative;
}
  .canvasBox:hover {
      border: 2px solid blue;
  }
  